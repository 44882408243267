/* .App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
} */

.form-check input {
  height: 21px;
}

.admin-profile.col-md-12 {
  padding: 44px 17px 3px 15px;
}
.categories_added {
  float: right;
  padding: 41px 33px -22px 30px;
  margin: -31px 11px -1px 0px;
}

.categories_added a {
  background-color: #f8f9fa !important;
  color: black !important;
  font-weight: bold;
}

.dropdown-menu.dropdown-menu-left.show {
  float: left !important;
  padding: -2px 0px 0px 0px;
  margin: 0px 0px 0px -120px;
}

.sweet-alert span.btn {
  padding: 2px 7px 0px 7px;
}

th.sorting {
  min-width: 320px !important;
}

.group-image img {
  min-width: 113px;
}

/* a:not([href]):not([tabindex]) {
  color:  #9c27b0;
  text-decoration: none;
} */

.group-image img {
  width: 8%;
}

.select_filter i {
  position: absolute;
  right: 9px;
  top: 16px;
}
.select_filter {
  position: relative;
}


/* .user_main-panel .content{
    margin-top: 70px;
    padding: 30px 15px;
    min-height: calc(100vh - 123px);

} */

.MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded {
  height: 400px;
}

.col-12.privacy h4 {
  font-weight: 800;
}

h1.heading {
  /* top: 89px !important; */
  margin-top: 50px;
}

.privacy h4 {
  font-weight: 800;
  margin-top: 22px;
}